import { visualization } from "@fscrypto/domain";
import { Input, Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@fscrypto/ui";

interface Props {
  viz: visualization.v3.Visualization;
  onChange: (viz: visualization.v3.Visualization) => void;
}
export const XAxisFormatting = ({ viz, onChange }: Props) => {
  const onXAxisLabelChange = (label: string) => {
    const updated = visualization.v3.updateXAxisLabel(viz, label);
    onChange(updated);
  };

  const onXAxisSortDirectionChange = (sortDirection: "asc" | "desc" | "none") => {
    const updated = visualization.v3.updateXAxisSort(viz, sortDirection);
    onChange(updated);
  };

  const { config } = viz;
  const reversed = config.options.xAxis?.reversed;
  if (!visualization.v3.isXYInput(config.inputs)) return null;
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col gap-y-1">
        <Label htmlFor="title">Label</Label>
        <Input value={config.options.xAxis?.title?.text ?? ""} onChange={(e) => onXAxisLabelChange(e.target.value)} />
      </div>
      <div className="flex flex-col gap-y-1">
        <Label htmlFor="subtitle">Sort Direction</Label>
        <Select
          value={reversed === undefined ? "none" : reversed ? "desc" : "asc"}
          onValueChange={onXAxisSortDirectionChange}
        >
          <SelectTrigger className="">
            <SelectValue placeholder="None" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="none">None</SelectItem>
            <SelectItem value="asc">Ascending</SelectItem>
            <SelectItem value="desc">Descending</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
