import * as R from "remeda";
import { visualization } from "@fscrypto/domain";
import { ColorPicker } from "@fscrypto/ui";
import { colors } from "@fscrypto/viz-2";

interface ColorFormattingProps {
  dataKeys: string[];
  viz: visualization.v3.Visualization;
  onChange: (viz: visualization.v3.Visualization, newKey: string, newColor: string) => void;
}

export const ColorFormatting = ({ dataKeys, viz, onChange }: ColorFormattingProps) => {
  const values = visualization.v3.isHeatmapInput(viz.config.inputs)
    ? {
        minColor: viz.config.options.colorAxis?.minColor ?? colors.categorical[0],
        maxColor: viz.config.options.colorAxis?.maxColor ?? colors.categorical[1],
      }
    : viz.config.inputs.config.colors
      ? viz.config.inputs.config.colors
      : R.fromKeys(dataKeys, (_, i) => colors.categorical[i]);

  return (
    <div className="flex flex-col gap-y-2">
      {dataKeys.map((d) => (
        <div key={d} className="flex items-center gap-x-2">
          <ColorPicker value={values[d] as string} onChange={(c) => onChange(viz, c, d)} />
          <span className="truncate">{d}</span>
        </div>
      ))}
    </div>
  );
};
