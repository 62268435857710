import { visualization } from "@fscrypto/domain";
import { Checkbox, Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@fscrypto/ui";

interface Props {
  viz: visualization.v3.Visualization;
  onChange: (viz: visualization.v3.Visualization) => void;
}

export const LegendFormatting = ({ viz, onChange }: Props) => {
  const onEnabledChanged = (enabled: boolean) => {
    const updated = visualization.v3.updateLegendEnabled(viz, enabled);
    onChange(updated);
  };

  const onPositionChanged = (position: string) => {
    const updated = visualization.v3.updateLegendPosition(viz, position as "top" | "bottom" | "left" | "right");
    onChange(updated);
  };

  const onReversedChanged = (reversed: boolean) => {
    const updated = visualization.v3.updateLegendReversed(viz, reversed);
    onChange(updated);
  };

  const { config } = viz;
  if (!visualization.v3.isXYInput(config.inputs)) return null;
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex gap-x-2">
        <Checkbox
          onCheckedChange={onEnabledChanged}
          checked={viz.config.options.legend?.enabled ?? true}
          id="enabled"
        />
        <Label htmlFor="enabled">Enabled</Label>
      </div>
      <div className="flex flex-col gap-y-1">
        <Label htmlFor="title">Position</Label>
        <Select
          value={
            config.options.legend?.layout === "horizontal" || !config.options.legend?.layout
              ? config.options.legend?.verticalAlign ?? "top"
              : config.options.legend?.align ?? "left"
          }
          onValueChange={onPositionChanged}
        >
          <SelectTrigger className="">
            <SelectValue placeholder="Position" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="bottom">Bottom</SelectItem>
            <SelectItem value="top">Top</SelectItem>
            <SelectItem value="left">Left</SelectItem>
            <SelectItem value="right">Right</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div className="flex gap-x-2">
        <Checkbox
          onCheckedChange={onReversedChanged}
          checked={
            viz.config.inputs.type === "heatmap"
              ? viz.config.options.colorAxis?.reversed ?? false
              : viz.config.options.legend?.reversed ?? false
          }
          id="reversed"
        />
        <Label htmlFor="reversed">Reversed</Label>
      </div>
    </div>
  );
};
