import { visualization } from "@fscrypto/domain";
import { Card, CardContent } from "@fscrypto/ui";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@fscrypto/ui/accordion";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@fscrypto/ui/tabs";
import { ExternalLink } from "lucide-react";
import { ChangeTypeDropdown } from "./change-type";
import { CodeEditor } from "./code-editor";
import { BigNumberFields } from "./fields/big-number-fields";
import { HistogramFields } from "./fields/histogram-fields";
import { PieFields } from "./fields/pie-fields";
import { ScatterFields } from "./fields/scatter-fields";
import { TableFields } from "./fields/table-fields";
import { XYFields } from "./fields/xy-fields";
import { ColorFormatting } from "./formatting/colors";
import { LegendFormatting } from "./formatting/legend";
import { TitleFormatting } from "./formatting/title";
import { XAxisFormatting } from "./formatting/x-axis";
import { YAxisFormattingCandlestick, YAxisFormattingXY } from "./formatting/y-axis";

interface SettingsProps {
  fields: visualization.v3.ColumnTypePair[];
  onChange: (e: visualization.v3.Visualization) => void;
  onHighChartsApiClick: () => void;
  onOptionsChange: (o: Highcharts.Options) => void;
  onTabClick: (tab: "fields" | "format" | "settings") => void;
  viz: visualization.v3.Visualization;
  options?: visualization.v3.Options;
  dataKeys: string[];
  dark?: boolean;
}

export const Settings = ({
  fields,
  viz,
  onChange,
  options,
  onOptionsChange,
  dataKeys,
  dark,
  onTabClick,
  onHighChartsApiClick,
}: SettingsProps) => {
  const showNestedYOptions =
    (visualization.v3.isXYInput(viz.config.inputs) && (viz.config.inputs.config.y?.length ?? 0) > 1) ||
    (visualization.v3.isBarLineInput(viz.config.inputs) && viz.config.inputs.config.line);
  return (
    <div className="bg-muted h-full overflow-hidden p-1">
      <Tabs defaultValue="fields" className="flex h-full w-full flex-col">
        <TabsList className="bg-muted w-full">
          <TabsTrigger value="fields" className="w-full" onClick={() => onTabClick("fields")}>
            Fields
          </TabsTrigger>
          <TabsTrigger value="format" className="w-full" onClick={() => onTabClick("format")}>
            Format
          </TabsTrigger>
          <TabsTrigger value="settings" className="w-full" onClick={() => onTabClick("settings")}>
            Settings
          </TabsTrigger>
        </TabsList>
        <div className="overflow-scroll">
          <TabsContent value="fields" className="flex flex-col gap-y-2 px-1">
            {visualization.v3.isXYInput(viz.config.inputs) && !visualization.v3.isHistogramInput(viz.config.inputs) && (
              <>
                <XYFields fields={fields} viz={viz} onChange={onChange} />
                {visualization.v3.isScatterInput(viz.config.inputs) && (
                  <ScatterFields fields={fields} viz={viz} onChange={onChange} />
                )}
              </>
            )}
            {visualization.v3.isPieInput(viz.config.inputs) && (
              <PieFields fields={fields} viz={viz} onChange={onChange} />
            )}
            {visualization.v3.isTableInput(viz.config.inputs) && (
              <TableFields fields={fields} viz={viz} onChange={onChange} />
            )}
            {visualization.v3.isBigNumberInput(viz.config.inputs) && options?.bigNumber && (
              <BigNumberFields fields={fields} viz={viz} onChange={onChange} options={options} />
            )}
            {visualization.v3.isHistogramInput(viz.config.inputs) && (
              <HistogramFields fields={fields} viz={viz} onChange={onChange} />
            )}
          </TabsContent>
          <TabsContent value="format" className="flex flex-col gap-y-2 px-1">
            <Card>
              <CardContent>
                <ChangeTypeDropdown viz={viz} onChange={onChange} />
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <TitleFormatting viz={viz} onChange={onChange} />
              </CardContent>
            </Card>
            <Accordion type="multiple" className="flex flex-col gap-y-4">
              {visualization.v3.isXYInput(viz.config.inputs) && (
                <>
                  <AccordionItem value="xAxis">
                    <Card>
                      <CardContent>
                        <AccordionTrigger>X Axis</AccordionTrigger>
                        <AccordionContent>
                          <XAxisFormatting viz={viz} onChange={onChange} />
                        </AccordionContent>
                      </CardContent>
                    </Card>
                  </AccordionItem>
                  {viz.config.inputs.config.y?.length == 1 && !showNestedYOptions && (
                    <AccordionItem value="yAxis">
                      <Card>
                        <CardContent>
                          <AccordionTrigger>Y Axis</AccordionTrigger>
                          <AccordionContent>
                            <YAxisFormattingXY
                              viz={viz}
                              axis={{ type: "array", index: 0 }}
                              yConfig={viz.config.inputs.config.y[0]}
                              onChange={onChange}
                            />
                          </AccordionContent>
                        </CardContent>
                      </Card>
                    </AccordionItem>
                  )}
                  {visualization.v3.isCandlestick(viz.config.inputs) && (
                    <AccordionItem value={`yAxis`} className="p-1">
                      <Card>
                        <CardContent>
                          <AccordionTrigger>Y Axis</AccordionTrigger>
                          <AccordionContent>
                            <YAxisFormattingCandlestick viz={viz} yAxis={options?.yAxis} onChange={onChange} />
                          </AccordionContent>
                        </CardContent>
                      </Card>
                    </AccordionItem>
                  )}
                  {showNestedYOptions && (
                    <AccordionItem value="yAxis">
                      <Card>
                        <CardContent>
                          <AccordionTrigger>Y Axis</AccordionTrigger>
                          <AccordionContent>
                            <Accordion type="multiple" className="flex flex-col gap-y-2">
                              {viz.config.inputs.config.y?.map((config, index) => (
                                <AccordionItem
                                  value={`yAxis-${config.key}`}
                                  className="p-1"
                                  key={`yAxis-${config.key}`}
                                >
                                  <Card>
                                    <CardContent>
                                      <AccordionTrigger>{config.key}</AccordionTrigger>
                                      <AccordionContent>
                                        <YAxisFormattingXY
                                          viz={viz}
                                          axis={{ type: "array", index }}
                                          yConfig={config}
                                          onChange={onChange}
                                        />
                                      </AccordionContent>
                                    </CardContent>
                                  </Card>
                                </AccordionItem>
                              ))}
                              {visualization.v3.isBarLineInput(viz.config.inputs) && viz.config.inputs.config.line && (
                                <AccordionItem value={`yAxis-line`} className="p-1">
                                  <Card>
                                    <CardContent>
                                      <AccordionTrigger>{viz.config.inputs.config.line.key} (Line)</AccordionTrigger>
                                      <AccordionContent>
                                        <YAxisFormattingXY
                                          viz={viz}
                                          axis={{ type: "barLine" }}
                                          yConfig={viz.config.inputs.config.line}
                                          onChange={onChange}
                                        />
                                      </AccordionContent>
                                    </CardContent>
                                  </Card>
                                </AccordionItem>
                              )}
                            </Accordion>
                          </AccordionContent>
                        </CardContent>
                      </Card>
                    </AccordionItem>
                  )}
                </>
              )}
              {!visualization.v3.isTableInput(viz.config.inputs) &&
                !visualization.v3.isBigNumberInput(viz.config.inputs) && (
                  <>
                    <AccordionItem value="Legend">
                      <Card>
                        <CardContent>
                          <AccordionTrigger>Legend</AccordionTrigger>
                          <AccordionContent>
                            <LegendFormatting viz={viz} onChange={onChange} />
                          </AccordionContent>
                        </CardContent>
                      </Card>
                    </AccordionItem>
                    <AccordionItem value="colors">
                      <Card>
                        <CardContent>
                          <AccordionTrigger>Colors</AccordionTrigger>
                          <AccordionContent>
                            <ColorFormatting
                              onChange={(viz, newColor, newKey) =>
                                visualization.v3.isHeatmapInput(viz.config.inputs)
                                  ? onChange(visualization.v3.updateHeatmapColors(viz, newKey, newColor))
                                  : onChange(visualization.v3.updateColors(viz, newKey, newColor))
                              }
                              dataKeys={
                                visualization.v3.isCandlestick(viz.config.inputs)
                                  ? ["downwardTrend", "upwardTrend"]
                                  : visualization.v3.isHeatmapInput(viz.config.inputs)
                                    ? ["minColor", "maxColor"]
                                    : dataKeys
                              }
                              viz={viz}
                            />
                          </AccordionContent>
                        </CardContent>
                      </Card>
                    </AccordionItem>
                  </>
                )}
            </Accordion>
          </TabsContent>
          <TabsContent value="settings" className="h-full">
            <a
              href="https://api.highcharts.com/highcharts"
              target="_blank"
              rel="noreferrer"
              className="text-foreground flex items-center justify-end gap-x-1 text-sm"
              onClick={onHighChartsApiClick}
            >
              Highcharts API reference
              <ExternalLink size={12} />
            </a>
            {options && <CodeEditor onChange={onOptionsChange} options={options} dark={dark} />}
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
};
